.App {
  text-align: center;
  color: black;
}

.App-logo {
  width: 200px;
  box-shadow: 20px 18px 31px -17px rgba(0,0,0,0.75);
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
p {
  font-family: 'Open Sans', sans-serif;
}
h2, h3, b {
  font-family: 'Lora', serif;
  
}
h2 {
  font-size: calc(20px + 4vmin);
}
h3 {
  font-size: calc(14px + 2vmin);
}
p {
  font-size: 18px;
}
.Slide {
  background-color: rgb(240, 240, 240);
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: black;
  padding: 0 20px;
  text-align: center;
}
a {
  color: red;
  font-weight: bold;
  text-decoration: none;
}
a:hover, a:focus {
  color: gray;
}
.content {
  margin: 40px 0 0;
  display: inline-block;
}
.content.two {
  margin: -30px 0 0;
}
.content.two h3 {
    margin: 40px auto;
    max-width: 490px;
}
.content.three h3 {
  margin: 10px auto 50px;
  font-size: calc(24px + 2vmin);
}
.ekg {
    width: 250px;
    height: 70px;
    padding: 20px 0 90px;
    clear: both;
    display: block;
    margin: 0 auto;
}
.content.three .ekg {
    padding: 60px 0 90px;
}
/* .floater {

} */
.floater p {
  margin: 20px 0 0;
}
.floater h2 {
    margin: 5px auto;
    width: 270px;
}
.floater h3 {
  margin: 10px 0;
}
.Slide.Flip {
  background-color: #c8242d;
  color: white;
}
.column {
  width: 100%;
}
.inset {
  background: white;
  border: 4px solid black;
  color: black;
  padding: 0px 20px 10px;
  text-align: left;
  box-shadow: 20px 18px 31px -17px rgba(0,0,0,0.75);
}

.inset b {
  font-size: 21px;
}

.inset span {
    font-family: 'Lora', serif;
    font-weight: bold;
    font-size: 20px;
}

.by {
  margin: 30px 10px 10px;
}
.author {
  width: 270px;
  height: 406px;
  box-shadow: 20px 18px 31px -17px rgba(0,0,0,0.75);
}
.blurb {
    background: white;
    padding: 10px 30px;
    text-align: left;
    margin: 30px 0;
    box-shadow: 20px 18px 31px -17px rgba(0,0,0,0.75);
    position: relative;
    border: 4px solid red;
}
.blurb--author {
  font-family: 'Lora', serif;
  font-size: 20px;
}
.blurb--author span {
  font-weight: bold;
}
.copyright {
  background: #c8242d;
  font-size: 12px;
  margin: 0 -20px;
}
.copyright p {
  color: white;
  display: inline-block;
}

.quotationmarks {
  position: absolute;
  width: 30px;
  transform: rotate(180deg);
  top: 6px;
  left: -7px;
}

.viewport-wrap {
  min-height: 100vh;
  position: relative;
  margin: 0 auto;
}
.content.one {
    height: 560px;
    width: 100%;
    min-height: auto;
    position: absolute;
    top: 50%;
    margin-top: -272px;
    left: 0;
}
@media (max-width: 1040px) and (max-height: 590px) {
  .viewport-wrap {
    height: 620px;
  }
  .content.one {
    top: 0;
    margin-top: 30px;
  }
}


@media (min-width: 600px) {
  .floater h2 {
    width: auto;
  }
  .column {
    width: 70%;
    max-width: 600px;
  }

  .content {
      width: 600px;
  }
  .content.one {
    width: 100%;
  }
/* 
  .Slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } */
}
.hide-mobile {
  display: none;
}
@media (min-width: 1040px) {
  .sticky-wrap {
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: 580px;
  }
  .ekg, .content.three .ekg {
    width: 360px;
    height: 94px;
    padding: 250px 0 0px;
  }
  .content.two h3 {
    max-width: 720px;
    margin: 0px 100px 50px;
    font-size: 35px;
  }
  .content.three {
    padding-bottom: 100px;
      box-shadow: 20px 18px 31px -17px rgba(0,0,0,0.75);

  }
  .content.three h3 {
    margin-top: 0px;
    width: 500px;
    font-size: 42px;
  }
  .author {
    position: sticky;
    top: 30px;
  }
  .inset {
    position: relative;
    width: 490px;
    padding: 10px 30px 20px;
  }
  .hide-mobile {
    display: block;
  }
  .hide-desktop {
    display: none;
  } 
  .App-logo {
    width: 360px;
    height: 556px;
    margin-top: 0;
    float: left;
  }
  .content {
      width: 1000px;
  }
  .viewport-wrap {
    min-height: 100vh;
    position: relative;
    width: 830px;
    margin: 0 auto;
  }
  .content.one {
    height: 700px;
    width: 100%;
    min-height: auto;
    position: absolute;
    top: 50%;
    margin-top: -330px;
    left: 0;
  }
  .content.two {
    width: 855px;
    padding-bottom: 100px;
  }

  .floater {
    text-align: left;
    float: left;
    width:420px; 
    padding-top:100px;
  }
  .by {
    clear: both;
    font-size: 35px;
    padding-top: 55px;
  }
  .floater {
    margin-left: 50px;
  }
  .floater h2 {
    font-size: 80px;
    width: 340px;
    line-height: 1.1;
    margin:0;
  }
  .floater:after {
    content: ' ';
    clear: both;
    display: table;
  }
  .blurb {
    margin: 30px 0;
  }
  .blurbholder {
    float: left;
    width: calc(50% - 10px);
    margin-right:20px;
  }
  .blurbholder:last-of-type {
    margin-right: 0;
  }
  .blurbholder:last-of-type:after {
    content: ' ';
    clear: both;
    display: table;
  }
}

@media (min-width: 1040px) and (max-height: 720px) {
  .viewport-wrap {
    height: 770px;
  }
  .content.one {
    top: 0;
    margin-top: 70px;
  }
}
@media (min-width: 1040px) {
  .Slide__wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height:100vh;
  }
  .Slide {
    position: sticky;
    top:0;
    box-shadow: 20px 18px 31px -17px rgba(0,0,0,0.75);
  }
  .Slide__wrap.one {
    z-index: 10;
  }
  .Slide__wrap.two {
    z-index:7;
  }
  .Slide__wrap.three {
    height: 300vh;
  }
}